<template>
  <DialogueBox
    data-auto-id="token-delete-dialogue"
    :showing="showing"
    title="Confirm Deletion"
  >
    <div class="p-4">
      <p>Are you sure you want to remove this token?</p>
      <dl class="mb-8">
        <dt data-auto-id="token-delete-name" class="font-bold">
          {{ deleteQueue.name ? deleteQueue.name : '(no name)' }}
        </dt>
        <dd data-auto-id="token-delete-token" class="text-tiny text-gray-dark">
          {{ deleteQueue.token }}
        </dd>
      </dl>
      <div class="flex items-center">
        <button
          data-auto-id="token-delete-confirm"
          @click.prevent="$emit('confirm')"
          class="m-0 mr-4"
        >
          Confirm
        </button>
        <a
          href="#!"
          @click.prevent="$emit('cancel')"
          class="text-gray-darkest no-underline"
          >Cancel</a
        >
      </div>
    </div>
  </DialogueBox>
</template>

<script>
import DialogueBox from '../../../Common/DialogueBox'
export default {
  name: 'TokenDeleteDialogue',
  components: {
    DialogueBox,
  },
  props: {
    showing: Boolean,
    deleteQueue: Object,
  },
}
</script>
