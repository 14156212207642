<template>
  <MainLayout>
    <div v-if="showGlobalError" class="bg-error text-white p-4 mb-8">
      {{ errorMessage }}
    </div>
    <NoTokens
      v-if="statusZero"
      :create-token="createInitialToken"
      :loading="status === 'zero.adding'"
      :errorMessage="errorMessage"
    />
    <div
      v-if="status === 'loading'"
      class="w-full flex items-center justify-center"
    >
      <ToznyLoader class="text-tozny w-24 h-24" />
    </div>
    <TokenList
      v-if="statusIdle"
      :tokens="tokens"
      @delete="beginDelete"
      @add="transitionStatus('add')"
    />
    <AddToken
      v-if="statusAdding"
      :create-token="addToken"
      :loading="status === 'adding'"
      :errorMessage="errorMessage"
      @cancel="transitionStatus('idle')"
    />
    <TokenDeleteDialogue
      :showing="showDeleteDialogue"
      :delete-queue="deleteQueue"
      @confirm="confirmDelete"
      @cancel="cancelDeleteQueue"
    />
  </MainLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AddToken from './Components/AddToken'
import MainLayout from '@/Common/MainLayout/MainLayout'
import NoTokens from './Components/NoTokens'
import TokenDeleteDialogue from './Components/TokenDeleteDialogue'
import TokenList from './Components/TokenList'
import ToznyLoader from '../../Common/ToznyLoader'

export default {
  name: 'Tokens',
  components: {
    AddToken,
    MainLayout,
    NoTokens,
    TokenDeleteDialogue,
    TokenList,
    ToznyLoader,
  },
  computed: {
    ...mapState('tokens', ['status', 'tokens', 'errorMessage', 'deleteQueue']),
    statusZero() {
      return this.status === 'zero' || this.status === 'zero.adding'
    },
    statusIdle() {
      return this.status === 'idle' || this.status === 'idle.delete'
    },
    statusAdding() {
      return this.status === 'add' || this.status === 'adding'
    },
    showDeleteDialogue() {
      return this.status === 'idle.delete'
    },
    showGlobalError() {
      return (
        (this.status === 'idle' && this.errorMessage) ||
        this.status === 'globalError'
      )
    },
  },
  methods: {
    ...mapActions('tokens', [
      'initialize',
      'loadTokens',
      'createToken',
      'enqueueDelete',
      'cancelDeleteQueue',
      'deleteToken',
      'transitionStatus',
    ]),
    async createInitialToken(token) {
      await this.transitionStatus('zero.adding')
      await this.createToken(token)
    },
    async addToken(token) {
      await this.transitionStatus('adding')
      await this.createToken(token)
    },
    beginDelete(tokenString) {
      const token = this.tokens.find((t) => t.token === tokenString)
      return this.enqueueDelete(token)
    },
    confirmDelete() {
      return this.deleteToken(this.deleteQueue)
    },
  },
  created: async function () {
    await this.initialize()
  },
}
</script>

<style></style>
