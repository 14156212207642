<template>
  <section>
    <ContentCard data-auto-id="token-add" title="Registration Tokens">
      <div class="py-8 px-4">
        <TokenForm
          @submit="createToken"
          @cancel="$emit('cancel')"
          :loading="loading"
          :error="errorMessage"
        />
      </div>
    </ContentCard>
  </section>
</template>

<script>
import ContentCard from '../../../Common/ContentCard'
import TokenForm from './TokenForm'
export default {
  name: 'AddTokens',
  components: {
    ContentCard,
    TokenForm,
  },
  props: {
    createToken: Function,
    loading: Boolean,
    errorMessage: String,
  },
}
</script>
