<template>
  <section>
    <ContentCard data-auto-id="token-list" title="Registration Tokens">
      <template #extras>
        <Actions
          :actions="cardActions"
          @action="handleCardActions"
          auto-id="token-card"
        />
      </template>
      <div class="pt-8 px-4 pb-4">
        <p>
          Client Registration Tokens allow programatic creation of clients in
          your applications. You can use these tokens to create clients from
          mobile applications, web apps, and any other place where our SDK's are
          supported.
        </p>
        <p class="mb-8">
          There is no limit to the number of registration tokens you can have.
        </p>
        <ItemTable
          data-auto-id="token-table"
          :items="tokens"
          :columns="tableColumns"
        >
          <template #actions="{ itemData }">
            <Actions
              :actions="actionObject(itemData)"
              @action="handleAction"
              :auto-id="`token-actions-${itemData}`"
            />
          </template>
          <template #token="{ itemData }">
            <span class="text-tiny lg:text-base break-all">{{ itemData }}</span>
          </template>
        </ItemTable>
      </div>
    </ContentCard>
  </section>
</template>

<script>
import Actions from '../../../Common/Actions'
import ContentCard from '../../../Common/ContentCard'
import ItemTable from '../../../Common/ItemTable'
export default {
  name: 'TokenList',
  components: {
    Actions,
    ContentCard,
    ItemTable,
  },
  data: () => ({
    actions: [
      {
        event: 'delete',
        altText: 'delete token',
        icon: 'delete-outline',
      },
    ],
  }),
  props: {
    tokens: {
      type: Array,
      default: () => [],
      validator(items) {
        for (let item of items) {
          if (typeof item.name !== 'string') {
            return false
          }
          if (typeof item.token !== 'string' || !item.token) {
            return false
          }
        }
        return true
      },
    },
    errorMessage: String,
  },
  computed: {
    tableColumns() {
      return [
        {
          title: 'Nickname',
          target: 'name',
          slot: 'name',
        },
        {
          title: 'Token',
          target: 'token',
          slot: 'token',
        },
        {
          title: 'Actions',
          target: 'token',
          slot: 'actions',
          className: 'whitespace-no-wrap w-px text-center',
        },
      ]
    },
    cardActions() {
      return [
        {
          event: 'add',
          altText: 'add token',
          icon: 'add-outline',
        },
      ]
    },
  },
  methods: {
    handleAction(action) {
      this.$emit(action.event, action.token)
    },
    handleCardActions(action) {
      this.$emit(action.event)
    },
    actionObject(token) {
      return [
        {
          token: token,
          event: 'delete',
          altText: 'delete token',
          icon: 'delete-outline',
        },
      ]
    },
  },
}
</script>
